import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { isNil } from 'lodash'
import { NotificationSettings } from '@mth/enums'
import {
  getNotificationSettingByPersonId,
  saveNotificationSettingsMutation,
} from '@mth/graphql/queries/notification-settings'
import { getPhoneAvailability } from '@mth/graphql/queries/participation-tracking'
import { NotificationsSettingType } from '@mth/types'

export const useNotificationSettings = (userPersonId: number) => {
  const [notificationSettings, setNotificationSettings] = useState<NotificationsSettingType | null>(null)
  const [selectedSetting, setSelectedSetting] = useState<NotificationSettings | null>(NotificationSettings.EMAIL)
  const [saveNotificationSetting, { loading: loadingSaveNotificationSettings }] = useMutation(
    saveNotificationSettingsMutation,
  )

  const { data: isPhoneAvailable, loading: phoneAvailableLoading } = useQuery(getPhoneAvailability, {
    variables: {
      personId: userPersonId,
    },
    skip: isNil(userPersonId),
    fetchPolicy: 'network-only',
  })

  const {
    data: notificationSettingData,
    loading: notificationSettingLoading,
    refetch: refetchSettings,
  } = useQuery(getNotificationSettingByPersonId, {
    variables: {
      personId: userPersonId,
    },
    skip: isNil(userPersonId) || !userPersonId,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isNil(notificationSettingData?.getNotificationSettings?.notification_type)) {
      setNotificationSettings(notificationSettingData.getNotificationSettings)
      setSelectedSetting(notificationSettingData.getNotificationSettings?.notification_type)
    }
  }, [userPersonId, notificationSettingData])

  const phoneAvailability = useMemo(() => {
    if (!isNil(isPhoneAvailable)) {
      return isPhoneAvailable.getPhoneAvailabilty
    }
    return false
  }, [isPhoneAvailable])
  return {
    notificationSettings,
    setNotificationSettings,
    selectedSetting,
    setSelectedSetting,
    phoneAvailableLoading,
    phoneAvailability,
    saveNotificationSetting,
    loadingSaveNotificationSettings,
    notificationSettingLoading,
    refetchSettings,
  }
}
