import { MthTitle, submitOptions } from '@mth/enums'

export const submitOptionsArray = [
  {
    label: MthTitle.SUBMIT,
    value: submitOptions.SUBMIT,
  },
  {
    label: MthTitle.SAVE_AS_DRAFT,
    value: submitOptions.SAVE_AS_DRAFT,
  },
]
