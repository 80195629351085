export enum LearningLogQuestionActions {
  CONTINUE = 1,
  SHOW_CONDITIONAL_QUESTION,
}

export enum LearningLogGradeDisplay {
  EXCUSED = 'EX',
  NANDA = 'N/A',
}

export enum submitOptions {
  SUBMIT = 1,
  SAVE_AS_DRAFT,
}
