export enum MthColor {
  TANGERINE = '#F24915',
  SKY = '#03A4EA',
  ICE = '#FAFAF6',
  VORTEX = '#141A1F',
  ACTIVE_LINK_BG = 'rgba(242, 73, 21, 0.04)',
  RGBA_PURPLE = 'rgba(62, 39, 131, 0)',

  RED = '#D23C33',
  RED_SECONDARY = 'red',
  GRAY = '#CCCCCC',
  GRAY_7F = '#7f7f7f',
  GRAY_BD = '#BDBDBD',
  LIGHTGRAY = '#F2F2F2',
  MEDIUMGRAY = '#D1D1D1',
  DARKGRAY = '#545454',
  BLACK = '#000000',
  MTHBLUE = TANGERINE,
  // @ts-ignore
  MTHBLUE_DISABLED = `${VORTEX}80`,
  MTHPURPLE = VORTEX,
  MTHORANGE = '#EC5925',
  MTHGREEN = '#2B9EB7',
  GREEN = '#38892B',
  LIGHTGREEN = 'rgba(50,200,23,0.3)',
  LIGHTGREEN2 = GREEN,
  LIGHTPURPLE = VORTEX,
  LIGHTRED = '#fdeee9',
  LIGHTBLUE = '#ececff',
  WHITE = '#FFFFFF',
  MTHBLACK = '#333333',
  CERULEAN = '#00A4E5',
  BLUE = '#3A86CA',
  PURPLE = '#7E3F9D',

  LIGHTGRAY_02 = '#EEEEEE',

  BG_MAIN = '#FAFAFA',

  MTHLIGHTGRAY = 'rgba(26, 26, 26, 0.25)',

  SYSTEM_01 = '#0E0E0E',
  SYSTEM_02 = '#313131',
  SYSTEM_03 = '#575757',
  SYSTEM_04 = '#6E6E6E',
  SYSTEM_05 = '#767676',
  SYSTEM_06 = '#A1A1A1',
  SYSTEM_07 = GRAY,
  SYSTEM_08 = '#E7E7E7',
  SYSTEM_09 = '#EEF4F8',
  SYSTEM_10 = GRAY,
  SYSTEM_11 = '#898C8F',
  SYSTEM_12 = '#A3A3A4',
  SYSTEM_13 = '#1A1A1A',
  SYSTEM_14 = '#F5F5F5',

  SCROLLBAR_THUMB = '#888888',
  SCROLLBAR_THUMB_HOVER = '#555555',
  DROPDOWN_BORDER = 'rgba(0, 0, 0, 0.23)',
  PRIMARY_MEDIUM_MOUSEOVER = SKY,
  BLACK_MOUSEOVER = '#292626',
  PRIMARY_SMALL_DEFAULT = SKY,

  ERROR_SNACKBAR_BG = 'rgba(236, 89, 37, 0.7)',

  ORIGINAL_SETTINGS_BACKGROUND = 'background-color: rgb(250,250,250)',
  UPDATED_SETTINGS_BACKGROUND = 'background-color: rgb(255,255,255)',

  DISABLED = '#0E0E0E80',

  SHADOW = 'rgba(0, 0, 0, 0.05)',

  LIGHTREDBG = 'rgba(236, 89, 37, 0.1)',

  OVERLAY = '#535252',

  YELLOW = '#D3AD0A',
  YELLOW2 = '#FFD626',
  LL_LINK = '#038ac5',

  // @ts-ignore
  BUTTON_LINEAR_GRADIENT = `linear-gradient(to right, ${SKY}, ${SKY}), ${SKY}`,
  // @ts-ignore
  BUTTON_LINEAR_GRADIENT_DARK = `linear-gradient(to right, ${VORTEX}, ${VORTEX}), ${VORTEX}`,
  BUTTON_LINEAR_GRADIENT_DARK_HOVER = BUTTON_LINEAR_GRADIENT_DARK,
  // @ts-ignore
  RED_GRADIENT = `radial-gradient(circle, ${VORTEX} 0%, ${VORTEX} calc(100% - 8px), ${RED} calc(100% - 7px), ${RED} 100%), ${RED}`,
  BUTTON_RED_GRADIENT = RED_GRADIENT,
  // @ts-ignore
  BLUE_GRADIENT = `linear-gradient(to right, ${TANGERINE}, ${TANGERINE}), ${TANGERINE}`,
  // @ts-ignore
  YELLOW_GRADIENT = `radial-gradient(circle, ${VORTEX} 0%, ${VORTEX} calc(100% - 8px), ${YELLOW2} calc(100% - 7px), ${YELLOW2} 100%), ${YELLOW2}`,
  // @ts-ignore
  GREEN_GRADIENT = `radial-gradient(circle, ${VORTEX} 0%, ${VORTEX} calc(100% - 8px), ${GREEN} calc(100% - 7px), ${GREEN} 100%), ${GREEN}`,
  // @ts-ignore
  BLACK_GRADIENT = `radial-gradient(circle, ${VORTEX} 0%, ${VORTEX} calc(100% - 8px), ${SYSTEM_02} calc(100% - 7px), ${SYSTEM_02} 100%), ${SYSTEM_02}`,
  // @ts-ignore
  ORANGE_GRADIENT = `radial-gradient(circle, ${VORTEX} 0%, ${VORTEX} calc(100% - 8px), ${MTHORANGE} calc(100% - 7px), ${MTHORANGE} 100%), ${MTHORANGE}`,
  // @ts-ignore
  MTHGREEN_GRADIENT = `radial-gradient(circle, ${VORTEX} 0%, ${VORTEX} calc(100% - 8px), ${MTHGREEN} calc(100% - 7px), ${MTHGREEN} 100%), ${MTHGREEN}`,
}
