import React, { useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useRecoilState } from 'recoil'
import { WarningModal } from '@mth/components/WarningModal/Warning'
import {
  PersonInterventionLabels,
  INTERVENTION_STATUS,
  TEXT_SIZE,
  WarningMessagesButtons,
  WarningMessagesTitles,
  InterventionNone,
} from '@mth/enums'
import { createPersonInterventionMutation } from '@mth/graphql/mutation/person-intervention'
import { alertState } from '@mth/providers/Store/State'
import { PersonInterventionModalProps } from './PersonInterventionModal.props'
import { Paragraph } from '../shared/Paragraph'

export const PersonInterventionModal: React.FC<PersonInterventionModalProps> = ({
  handleSubmit,
  handleClose,
  personInterventionValue,
  setPersonInverventionValue,
  studentId,
  schoolYearId,
}) => {
  const [error, setError] = useState<boolean>(false)
  const [, setAlertSetting] = useRecoilState(alertState)
  const onError = (error: ApolloError) => {
    console.error(error.message)
    setError(true)
    setAlertSetting({
      show: true,
      message: 'Error occurred',
      type: 'error',
    })
  }
  const [valueSelected, setValueSelected] = useState<number>(personInterventionValue || InterventionNone)
  const [createOrUpdatePersonIntervention, { loading }] = useMutation(createPersonInterventionMutation, {
    onCompleted: (response: { createPersonIntervention: { success: boolean; message: string } }) => {
      if (response.createPersonIntervention.success) {
        handleSubmit()
        setPersonInverventionValue(valueSelected)
        setAlertSetting({
          show: true,
          message: 'Changes saved successfully',
          type: 'success',
        })
      } else {
        onError(new ApolloError({ errorMessage: response.createPersonIntervention.message }))
      }
    },
    onError: onError,
  })

  const internalHandleSubmit = () => {
    createOrUpdatePersonIntervention({
      variables: {
        createPersonInterventionInput: {
          intervention_status: valueSelected,
          student_ids: [studentId],
          school_year_id: schoolYearId,
        },
      },
    })
  }

  const handleOptionSelected = (option: number) => {
    setError(false)
    setValueSelected(option)
  }

  const getRadioButtons = () => {
    return (
      <>
        {Object.keys(PersonInterventionLabels).map((key) => (
          <FormControlLabel
            key={key}
            value={INTERVENTION_STATUS[key as keyof typeof INTERVENTION_STATUS] || InterventionNone}
            control={<Radio />}
            label={
              <Paragraph size={TEXT_SIZE.LARGE} fontWeight='600'>
                {PersonInterventionLabels[key as keyof typeof PersonInterventionLabels]}
              </Paragraph>
            }
          />
        ))}
      </>
    )
  }

  return (
    <WarningModal
      title={WarningMessagesTitles.UPDATE_INTERVENTION_STATUS}
      btntitle={WarningMessagesButtons.SAVE}
      canceltitle={WarningMessagesButtons.CANCEL}
      handleModem={handleClose}
      loadingButton={loading}
      handleSubmit={internalHandleSubmit}
      textCenter
      showIcon={false}
      modalWidth='500px'
      upperCase={false}
      error={error}
    >
      <Box sx={{ marginLeft: '40px', marginTop: '-30px', overflow: 'auto', textAlign: 'start', paddingLeft: '12px' }}>
        <FormControl>
          <RadioGroup
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={valueSelected}
            onChange={(e) => handleOptionSelected(Number(e.target.value))}
          >
            {getRadioButtons()}
          </RadioGroup>
        </FormControl>
      </Box>
    </WarningModal>
  )
}
