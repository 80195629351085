export enum ParticipationTrackingStatus {
  UNEXCUSED_ABSENCE = 1,
  SUBMITTED,
  EXCUSED_ABSENCE,
  HOLIDAY,
}

export enum ParticipationTrackingStatusLabel {
  NOT_SUBMITTED = 'Not Submitted',
  SUBMITTED = 'Submitted',
  UNEXCUSED_ABSENCE = 'Unexcused Absence',
  EXCUSED_ABSENCE = 'Excused Absence',
  HOLIDAY = 'Holiday',
}

const statusLabel = {
  [ParticipationTrackingStatus.UNEXCUSED_ABSENCE]: ParticipationTrackingStatusLabel.UNEXCUSED_ABSENCE,
  [ParticipationTrackingStatus.SUBMITTED]: ParticipationTrackingStatusLabel.SUBMITTED,
  [ParticipationTrackingStatus.EXCUSED_ABSENCE]: ParticipationTrackingStatusLabel.EXCUSED_ABSENCE,
  [ParticipationTrackingStatus.HOLIDAY]: ParticipationTrackingStatusLabel.HOLIDAY,
  default: ParticipationTrackingStatusLabel.NOT_SUBMITTED,
}

export const getParticipationStatusLabel = (status: ParticipationTrackingStatus | null) => {
  return status ? statusLabel[status] : statusLabel.default
}

export enum ParticipationNotificationSettings {
  ALL = 1,
  EMAIL,
  SMS,
}

export enum ParticipationNotificationLabel {
  ALL = 'Text Message (SMS) and Email',
  EMAIL = 'Email Only',
  SMS = 'Text Message (SMS) Only',
}
