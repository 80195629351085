import { gql } from '@apollo/client'

export const getIntentToReEnrollAnswer = gql`
  query getIntentToReEnrollAnswer($schoolYearId: Float!, $studentId: Float!) {
    getIntentToReEnrollAnswer(school_year_id: $schoolYearId, student_id: $studentId) {
      answer_intent_to_reenroll
      reason_intent_to_reenroll
    }
  }
`

export const getStatusesByStudent = gql`
  query getStatusesByStudent($studentId: Float!) {
    getStatusesByStudent(student_id: $studentId) {
      status
      school_year_id
      student_id
    }
  }
`

export const getPacketsByStudent = gql`
  query getPacketsByStudent($studentId: Float!) {
    getPacketsByStudent(student_id: $studentId) {
      files {
        file_id
        kind
        mth_file_id
        packet_id
      }
      missing_files
      packet_id
      birth_place
      birth_country
      hispanic
      language
      language_home
      language_home_child
      language_friends
      language_home_preferred
      household_size
      household_income
      worked_in_agriculture
      military
      race
      living_location
      lives_with
      work_move
      last_school
      last_school_address
      last_school_type
      school_district
      agrees_to_policy
      ferpa_agreement
      permission_to_request_records
      photo_permission
      secondary_contact_first
      secondary_contact_last
      secondary_email
      secondary_phone
      status
      meta
      special_ed
      school_year_id
      signature_file_id
      reenroll
      deadline
      created_at
    }
  }
`
export const getITRAnswersByStudentsAndSY = gql`
  query getITRAnswersByStudentsAndSY($schoolYearId: Float!, $students: [Int!]!) {
    getITRAnswersByStudentsAndSY(school_year_id: $schoolYearId, students: $students) {
      student_id
    }
  }
`

export const getSchedulesByStudentId = gql`
  query ScheduleByStudentId($studentId: Int!) {
    scheduleByStudentId(studentId: $studentId) {
      schedule_id
      StudentId
      SchoolYearId
      status
      is_second_semester
    }
  }
`

export const getReenrollmentStatus = gql`
  query GetReenrollmentStatus($personId: Float!) {
    getReenrollmentStatus(person_id: $personId) {
      parentPersonId
      answer
      parent {
        person {
          user_id
        }
      }
    }
  }
`

export const saveReenrollmentAnswer = gql`
  mutation SaveReenrollmentAnswer($answer: Float!, $personId: Float!) {
    saveReenrollmentAnswer(answer: $answer, personId: $personId) {
      personId
      answer
    }
  }
`

export const getOpenEdToken = gql`
  query Query($userId: Float!) {
    getOpenEdToken(userId: $userId)
  }
`

export const getParentPerson = gql`
  query GetPersonDetails($personId: Float!) {
    getPersonDetails(personId: $personId) {
      uniqueId
    }
  }
`

export const sendITRAnswer = gql`
  mutation SaveITRHubspotContact($hubspotItrAnswers: HubSpotITRAnswerInput!) {
    saveITRHubspotContact(hubspotItrAnswers: $hubspotItrAnswers) {
      email
      uniqueId
      itrAnswer
    }
  }
`
