import { gql } from '@apollo/client'

export const createPersonInterventionMutation = gql`
  mutation CreatePersonIntervention($createPersonInterventionInput: CreatePersonInterventionInput!) {
    createPersonIntervention(createPersonInterventionInput: $createPersonInterventionInput) {
      success
      message
    }
  }
`
