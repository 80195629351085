import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Checkbox, FormControlLabel, Grid, Modal } from '@mui/material'
import { Box } from '@mui/system'
import { map } from 'lodash'
import { WarningModal } from '@mth/components/WarningModal/Warning'
import { MthLoadingWrapper } from '@mth/core/components/shared/MthLoadingWrapper/MthLoadingWrapper'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { MthColor } from '@mth/enums'
import useIsSafari from '@mth/hooks/useIsSafari/useIsSafari'
import { macScroll } from '@mth/styles/macScroll.style'
import { useStyles } from './styles'
import { ApolloError, NewModalProps } from './types'
import { CreateObserMutation } from '../../services'

export const StudentsModal: React.FC<NewModalProps> = ({
  handleModem,
  students = [],
  data,
  schoolYearId,
  regionId,
}) => {
  const classes = useStyles
  const [apolloError, setApolloError] = useState<ApolloError>({
    title: '',
    severity: '',
    flag: false,
  })
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  // @ts-ignore
  const handleChange = (id) => {
    // @ts-ignore
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id))
    } else {
      // @ts-ignore
      setSelected([...selected, id])
    }
  }
  const isSafari = useIsSafari()

  const [createObser] = useMutation(CreateObserMutation)

  const handleSubmit = async () => {
    setLoading(true)

    if (selected.length === 0) {
      setApolloError({ title: 'Please select students', severity: 'Warning', flag: true })
      setLoading(false)
      return
    }

    try {
      await createObser({
        variables: {
          observerInput: {
            // @ts-ignore
            parent_id: +data.parent_id,
            student_ids: selected.map((item) => +item),
            // @ts-ignore
            first_name: data.first_name,
            // @ts-ignore
            last_name: data.last_name,
            // @ts-ignore
            email: data.email,
            // @ts-ignore
            regions: data.regions,
            region_id: regionId,
            school_year_id: Number(schoolYearId),
          },
        },
      })

      handleModem(true)
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Mutation Error:', error)
        setApolloError({
          title: error.message || 'An unexpected error occurred',
          severity: 'Error',
          flag: true,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <MthLoadingWrapper loading={loading}>
        <Modal open={true} onClose={() => handleModem()}>
          <Box
            sx={{ ...classes.modalStudentCard, overflowY: 'auto', maxHeight: '90vh', ...(!isSafari ? macScroll : {}) }}
          >
            {apolloError.flag && (
              <WarningModal
                handleModem={() => setApolloError({ title: '', severity: '', flag: false })}
                title={apolloError.severity}
                subtitle={apolloError.title}
                btntitle='Close'
                handleSubmit={() => setApolloError({ title: '', severity: '', flag: false })}
              />
            )}
            <Box sx={classes.header}>
              <Subtitle>Select students the Observer has access to</Subtitle>
              <CloseIcon style={classes.close} onClick={() => handleModem()} />
            </Box>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Subtitle fontWeight='700' size='large' sx={{ marginBottom: '20px' }}>
                  Students
                </Subtitle>
                <Box sx={{ overflowY: 'auto', maxHeight: '50vh', ...(!isSafari ? macScroll : {}) }}>
                  {map(students, (student, index) => (
                    <Grid item xs={12}>
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            // @ts-ignore
                            checked={selected.includes(student.student_id)}
                            // @ts-ignore
                            onChange={() => handleChange(student.student_id)}
                          />
                        }
                        // @ts-ignore
                        label={student.person.first_name + ' ' + student.person.last_name}
                      />
                    </Grid>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Button
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      height: 29,
                      color: 'white',
                      width: '92px',
                      background: MthColor.RED_GRADIENT,
                      marginRight: 3,
                      '&:hover': {
                        background: MthColor.RED,
                        color: '#fff',
                      },
                    }}
                    onClick={() => handleModem()}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    disabled={loading}
                    sx={{
                      background: MthColor.BUTTON_LINEAR_GRADIENT,
                      color: 'white',
                      width: '92px',
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 700,
                      height: 29,
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </MthLoadingWrapper>
    </>
  )
}
