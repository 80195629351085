export const setStorageValue = <T>(key: string, value: T): void => {
  try {
    const serializedValue = JSON.stringify(value)
    sessionStorage.setItem(key, serializedValue)
    localStorage.setItem(key, serializedValue)
  } catch (error) {
    console.error('Error storing value:', error)
  }
}

export const getStorageValue = <T>(key: string): T | null => {
  try {
    const sessionValue = sessionStorage.getItem(key)
    if (sessionValue !== null) {
      return JSON.parse(sessionValue) as T
    }

    const localValue = localStorage.getItem(key)
    if (localValue !== null) {
      return JSON.parse(localValue) as T
    }

    return null
  } catch (error) {
    console.error('Error retrieving value:', error)
    return null
  }
}
