import { gql } from '@apollo/client'

export const getNotificationSettingByPersonId = gql`
  query getNotificationSettings($personId: Int!) {
    getNotificationSettings(personId: $personId) {
      id
      person_id
      notification_type
      created_at
      updated_at
      deleted_at
    }
  }
`

export const saveNotificationSettingsMutation = gql`
  mutation createOrUpdateNotificationSettings($personId: Int!, $notificationType: Int!) {
    createOrUpdateNotificationSettings(personId: $personId, notificationType: $notificationType) {
      id
    }
  }
`
