import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import { isNil, isObject } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { EMPTY_STRING, EPIC_6112_TASK_6277 } from '@mth/constants'
import { HubspotITRAnswers } from '@mth/enums'
import { PUBLIC_OPEN_ED_API } from '@mth/envs'
import {
  getOpenEdToken,
  getParentPerson,
  getReenrollmentStatus,
  saveReenrollmentAnswer,
  sendITRAnswer,
} from '@mth/graphql/queries/intent-to-reenroll'
import { ReenrollmentRequest } from '@mth/types'

export const useGetITRUsers = (
  personId: number,
  newPlatFormFlag: boolean,
): {
  showITRModal: boolean
  setShowITRModal: (arg: boolean) => void
  canShowITRModal: boolean
  setForceShowITRModal: (arg: boolean) => void
  familyReenrollmentLoading: boolean
  submitITR: (
    answer: boolean,
    email?: string,
    uniqueId?: string,
    regionId?: number,
    itrAnswer?: string,
    extraField?: string,
  ) => void
  onCancel: () => void
  uniqueId: string | undefined
  showFinalMessage: boolean
  selectedItrOption: string
  setSelectedItrOption: (arg: string) => void
  extraFieldContent: string
  setExtraFieldContent: (arg: string) => void
  itrHubspotLoading: boolean
  showErrorMessage: boolean
  setShowErrorMessage: (arg: boolean) => void
  showSubmissionError: boolean
} => {
  const location = useLocation()
  const history = useHistory()
  const epic6112task6277 = useFlag(EPIC_6112_TASK_6277)
  const [showITRModal, setShowITRModal] = useState<boolean>(false)
  const [forceShowITRModal, setForceShowITRModal] = useState<boolean>(false)
  const [userId, setUserId] = useState<number>()
  const [uniqueId, setUniqueId] = useState<string>()
  const [showFinalMessage, setShowFinalMessage] = useState<boolean>(false)
  const [selectedItrOption, setSelectedItrOption] = useState<string>(HubspotITRAnswers.YES)
  const [extraFieldContent, setExtraFieldContent] = useState<string>('')
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
  const [showSubmissionError, setShowSubmissionError] = useState<boolean>(false)
  const [canShowITRModal, setCanShowITRModal] = useState<boolean>(false)

  const isITRLoginTrigger = useMemo(() => {
    return !!(isObject(location?.state) && 'showITRPopup' in location?.state && location.state?.showITRPopup)
  }, [location])

  const removeITRLoginTrigger = () => {
    history.replace({
      ...history.location,
      state: {
        ...(history.location.state || {}),
        showITRPopup: undefined,
      },
    })
  }

  const { data: familyReenrollment, loading: familyReenrollmentLoading } = useQuery<ReenrollmentRequest>(
    getReenrollmentStatus,
    {
      variables: {
        personId: personId,
      },
      skip: !personId,
      fetchPolicy: 'network-only',
    },
  )
  const { data: parentPerson, loading: parentPersonLoading } = useQuery(getParentPerson, {
    variables: {
      personId: personId,
    },
    skip: !personId,
    fetchPolicy: 'network-only',
  })
  const [submitEnrollmentAnswer] = useMutation(saveReenrollmentAnswer)
  const [sendEnrollmentAnswerToHubspot, { loading: itrHubspotLoading }] = useMutation(sendITRAnswer)
  const [getUserToken] = useLazyQuery(getOpenEdToken, {
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (!isNil(parentPerson?.getPersonDetails?.uniqueId) && !parentPersonLoading) {
      setUniqueId(parentPerson?.getPersonDetails?.uniqueId)
    }
  }, [parentPerson, parentPersonLoading])
  useEffect(() => {
    if (
      !isNil(uniqueId) &&
      !isNil(familyReenrollment?.getReenrollmentStatus?.[0]) &&
      isNil(familyReenrollment?.getReenrollmentStatus?.[0]?.answer) &&
      !familyReenrollmentLoading
    ) {
      setCanShowITRModal(true)
      setShowITRModal(true)
      setUserId(familyReenrollment?.getReenrollmentStatus?.[0].parent?.person?.user_id)
    } else {
      setShowITRModal(false)
      setForceShowITRModal(false)
      setForceShowITRModal(false)
    }
  }, [familyReenrollment, familyReenrollmentLoading, uniqueId])

  const requestToken = async () => {
    return getUserToken({
      variables: {
        userId: userId,
      },
    })
  }

  const submitITR = async (
    answer: boolean,
    email?: string,
    uniqueId?: string,
    regionId?: number | undefined,
    itrAnswer?: string,
    extraField?: string,
  ) => {
    if (newPlatFormFlag) {
      const redirect = encodeURIComponent('parents/migrate')
      const response = await requestToken()
      if (!response?.data?.getOpenEdToken) {
        console.error(`Couldn't extract token for user with ID ${userId}`)
      }
      const token = encodeURIComponent(response?.data?.getOpenEdToken)
      const redirectURL = `${PUBLIC_OPEN_ED_API}auth/login-token?token=${token}&redirect=${redirect}`

      window.open(redirectURL, '_blank')
      setShowITRModal(false)
      setForceShowITRModal(false)
      removeITRLoginTrigger()
    }

    if (
      [HubspotITRAnswers.YES_NEW, HubspotITRAnswers.YES_SOME].includes(selectedItrOption as HubspotITRAnswers) &&
      extraFieldContent === EMPTY_STRING
    ) {
      setShowErrorMessage(true)
      return
    }
    const hubspotRequestParams = {
      email,
      uniqueId,
      personId: personId,
      regionId,
      itrAnswer,
      ...(itrAnswer === HubspotITRAnswers.YES_SOME && { itrSplitFamily: extraField }),
      ...(itrAnswer === HubspotITRAnswers.YES_NEW && { itrAddStudent: extraField }),
      ...(itrAnswer === HubspotITRAnswers.NO && { itrReason: extraField }),
    }

    const hubspotResponse = await sendEnrollmentAnswerToHubspot({
      variables: {
        hubspotItrAnswers: hubspotRequestParams,
      },
    })

    if (hubspotResponse.data?.saveITRHubspotContact?.error) {
      setShowSubmissionError(true)
      return
    }

    await submitEnrollmentAnswer({
      variables: {
        answer: Number(answer),
        personId: personId,
      },
    })

    setCanShowITRModal(false)
    setShowFinalMessage(true)
  }

  const onCancel = async () => {
    setShowITRModal(false)
    setForceShowITRModal(false)
    setShowSubmissionError(false)
    setShowErrorMessage(false)
    removeITRLoginTrigger()
  }

  return {
    showITRModal: (showITRModal && isITRLoginTrigger) || (forceShowITRModal && epic6112task6277),
    setShowITRModal,
    canShowITRModal: canShowITRModal && epic6112task6277,
    setForceShowITRModal,
    familyReenrollmentLoading,
    submitITR,
    onCancel,
    uniqueId,
    showFinalMessage,
    setSelectedItrOption,
    selectedItrOption,
    extraFieldContent,
    setExtraFieldContent,
    itrHubspotLoading,
    showErrorMessage,
    setShowErrorMessage,
    showSubmissionError,
  }
}
