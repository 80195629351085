import React, { ReactElement, useEffect, useState } from 'react'
import { Theme } from '@emotion/react'
import { Box, Checkbox, Chip, FormControlLabel } from '@mui/material'
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox'
import { SxProps } from '@mui/system'
import { isNil } from 'lodash'
import { EMPTY_STRING } from '@mth/constants'
import { DisplayHTML } from '@mth/core/components/shared/DisplayHTML/DisplayHTML'
import { Paragraph } from '@mth/core/components/shared/Paragraph/Paragraph'
import { MthColor, MthTitle } from '@mth/enums'
import { extractContent } from '@mth/utils'

export interface MthCheckboxProps extends CheckboxProps {
  label?: string
  labelSx?: SxProps<Theme>
  wrapSx?: SxProps<Theme>
  faded?: boolean
  disableColorChangeWhenDisabled?: boolean
  disabled?: boolean
  dangerouslySetLabelHTML?: { __html: string }
  rowRequiredQuestionMark?: boolean
  element?: React.ReactElement
}

const MthCheckbox = (props: MthCheckboxProps): React.ReactElement => {
  const {
    label,
    element,
    labelSx,
    wrapSx,
    faded = false,
    disableColorChangeWhenDisabled = false,
    disabled,
    dangerouslySetLabelHTML,
    rowRequiredQuestionMark = false,
    ...otherProps
  } = props
  const [extractedLabel, setExtractedLabel] = useState(dangerouslySetLabelHTML?.__html)

  useEffect(() => {
    setExtractedLabel(extractContent(dangerouslySetLabelHTML?.__html ?? EMPTY_STRING))
  }, [dangerouslySetLabelHTML])

  const renderCheckbox = () => (
    <Box display='flex' flexDirection='column' justifyContent={'start'}>
      {rowRequiredQuestionMark && (
        <Chip
          size='small'
          label={MthTitle.REQUIRED}
          variant='outlined'
          color='error'
          sx={{ fontWeight: '400', fontSize: 10 }}
        />
      )}
      <Checkbox
        {...otherProps}
        disabled={!!disabled}
        sx={{
          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            color: faded ? MthColor.GRAY_7F : otherProps.color === 'secondary' ? MthColor.VORTEX : MthColor.SYSTEM_01,
          },
          '&:not(.Mui-disabled) .MuiSvgIcon-root': {
            color: faded ? MthColor.GRAY_7F : otherProps.color === 'secondary' ? MthColor.VORTEX : MthColor.SYSTEM_01,
          },
          '&.Mui-disabled:not(.Mui-checked)': {
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: otherProps.size === 'small' ? 16 : 18,
              height: otherProps.size === 'small' ? 16 : 18,
              borderRadius: '2px',
              border: disableColorChangeWhenDisabled
                ? `solid 2px ${MthColor.BLACK}`
                : `solid 2px ${MthColor.SYSTEM_12}`,
              backgroundColor: disableColorChangeWhenDisabled ? MthColor.WHITE : MthColor.SYSTEM_07,
            },
            '& svg': {
              opacity: 0,
            },
          },
          '&.Mui-checked.Mui-disabled': {
            '& svg': {
              opacity: disableColorChangeWhenDisabled ? 1 : 0.5,
            },
          },
          ...otherProps.sx,
        }}
      />
    </Box>
  )

  const renderContent = (): ReactElement => {
    if (!isNil(label)) {
      return <>{label}</>
    } else if (Boolean(extractedLabel)) {
      return <DisplayHTML text={dangerouslySetLabelHTML!.__html} />
    } else {
      return <>{EMPTY_STRING}</>
    }
  }

  return (
    <>
      {Boolean(label) || Boolean(extractedLabel) || Boolean(element) ? (
        <FormControlLabel
          control={renderCheckbox()}
          label={
            element || (
              <Paragraph
                size='large'
                sx={{
                  ml: rowRequiredQuestionMark ? 0 : 1.5,
                  mt: rowRequiredQuestionMark ? 5 : 0,
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: 1,
                  color: disabled ? MthColor.GRAY : MthColor.BLACK,
                  ...labelSx,
                }}
              >
                {renderContent()}
              </Paragraph>
            )
          }
          sx={{
            '.MuiFormControlLabel-label.Mui-disabled': {
              color: 'unset',
            },
            ml: 0,
            mr: 0,
            ...wrapSx,
          }}
        />
      ) : (
        renderCheckbox()
      )}
    </>
  )
}

export default MthCheckbox
