import { gql } from '@apollo/client'

export const getSignatureFile = gql`
  query getSignatureFile($fileId: ID!) {
    signatureFile(file_id: $fileId) {
      file_id
      is_new_upload_type
      item1
      item2
      item3
      signedUrl
      name
      type
      year
    }
  }
`

export const getSignatureFileByPath = gql`
  query getSignatureFileByPath($filePath: String!) {
    signatureFileByPath(file_path: $filePath) {
      file_id
      is_new_upload_type
      item1
      item2
      item3
      signedUrl
      name
      type
      year
    }
  }
`
