import { makeStyles } from '@material-ui/styles'
import { MthColor } from '@mth/enums'

export const styles = {
  tableBox: { width: '100%', padding: 3 },
  paginationBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginY: 4,
  },
  tableCell: {
    borderBottom: `1px solid ${MthColor.SYSTEM_08}`,
    borderTop: `1px solid ${MthColor.SYSTEM_08}`,
  },
  tableCellTypographyGrey: { fontSize: 14, fontWeight: 700, color: MthColor.SYSTEM_05 },
  tableCellTypographyDark: { fontSize: 14, fontWeight: 700, color: MthColor.SYSTEM_01 },
  tableTooltip: {
    width: '35px',
  },
  pageTop: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageTopRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: '24px',
  },
  addButton: {
    background: MthColor.BG_MAIN,
    color: 'black',
    width: '200px',
    height: '44px',
    alignItems: 'center',
    '&:hover': {
      background: MthColor.SYSTEM_14,
      color: MthColor.BLACK,
    },
  },
  pageTitle: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowIcon: {
    fontSize: '18px',
    fill: 'black',
    stroke: 'black',
    strokeWidth: 1,
    marginRight: '7px',
  },
}

// @ts-ignore
export const toolTipStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: MthColor.SYSTEM_05,
    fontSize: '14px',
    borderRadius: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 9,
    paddingBottom: 9,
  },
}))
