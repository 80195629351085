import React, { createContext } from 'react'
import { atom } from 'recoil'
import { STORAGE } from '@mth/enums'
import { Person, Student } from '@mth/models'
import { getStorageValue } from '@mth/utils'
import { AccessType, RegionType, RoleType } from './types'

export enum HyperwalletAccountStatus {
  PRE_ACTIVATED = 'PRE_ACTIVATED',
  ACTIVATED = 'ACTIVATED',
  LOCKED = 'LOCKED',
  FROZEN = 'FROZEN',
  DE_ACTIVATED = 'DE_ACTIVATED',
}

export enum HyperwalletVerificationStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED = 'REQUIRED',
  FAILED = 'FAILED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  VERIFIED = 'VERIFIED',
}

export type UserInfo = {
  hyperwalletUserToken?: string
  hyperwalletUserStatus?: HyperwalletAccountStatus
  hyperwalletUserVerificationStatus?: HyperwalletVerificationStatus
  hyperwalletProfileType?: string
  hyperwalletTreatmentToken?: string
  user_id?: number
  email?: string
  first_name?: string
  last_name?: string
  cookie?: string | undefined | null
  last_login?: Date | undefined | null
  avatar_url?: string | undefined | null
  level?: number | undefined | null
  role?: RoleType
  userRegion?: RegionType[]
  userAccess?: AccessType
  students?: Student[]
  profile?: Person
  selectedRegionId?: number
  masquerade: boolean
  currentTab?: number
  observableStudents?: Student[]
  selectedClassId?: number
}

export type UserContextType = {
  me: UserInfo | null
  setMe: React.Dispatch<React.SetStateAction<UserInfo | null>>
}

const userContext: UserContextType = {
  me: null,
  setMe: () => {},
}

export type TabInfo = {
  currentTab?: number
}
export type TabContextType = {
  tab: TabInfo | null
  setTab: React.Dispatch<React.SetStateAction<TabInfo | null>>
  visitedTabs: number[]
  setVisitedTabs: React.Dispatch<React.SetStateAction<number[] | null>>
}

const tabContext: TabContextType = {
  tab: null,
  setTab: () => {},
  visitedTabs: [],
  setVisitedTabs: () => {},
}

export const UserContext = createContext(userContext)

export const TabContext = createContext(tabContext)

function parseUserClassSelected() {
  return Number(getStorageValue(STORAGE.SELECTED_CLASS) || 0)
}

function parseUserRegionState() {
  return getStorageValue<RegionType>(STORAGE.SELECTED_REGION)
}

export const userRegionState = atom<RegionType | null>({
  key: 'userRegion',
  default: parseUserRegionState(),
})

export const userClassSelected = atom<number | null>({
  key: 'userClass',
  default: parseUserClassSelected(),
})
