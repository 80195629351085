import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { isFunction, isNil } from 'lodash'
import { Editor } from 'react-draft-wysiwyg'
import {
  ADMIN_OPTIONS,
  EMPTY_STRING,
  EPIC_5542_BUG_6038,
  openIframeRegex,
  openImgRegex,
  OPTIONS_TO_REMOVE,
} from '@mth/constants'
import { MthColor, fontFamilyEnum } from '@mth/enums'
import { getCleanEditorState, getOptions, handlePastedText } from '@mth/utils'
import { MthBulletEditorProps } from './MthBulletEditor.types'
import { bulletEditorClasses } from './styles'

const generateEditorState = (html: string): EditorState => {
  // If the html starts with img tag wrap it with <p></p>
  // to avoid the Wysiwyg crash, otherwise leave the html without change
  const htmlContent = openImgRegex.test(html) || openIframeRegex.test(html) ? `<p>${html}</p>` : html
  const contentBlock = htmlToDraft(htmlContent || EMPTY_STRING)
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  return EditorState.createWithContent(contentState)
}

const MthBulletEditor: React.FC<MthBulletEditorProps> = ({
  testId,
  value,
  setValue,
  handleFocusLost,
  isEditedByExternal,
  error,
  height,
  maxHeight,
  isBlockEnd = true,
  wordBreak,
  readOnly = false,
  whiteBackground = false,
  isOptionRemove = false,
  classStyle,
  hideToolbar = false,
}) => {
  const [currentBlocks, setCurrentBlocks] = useState<number>(0)
  const [editorState, setEditorState] = useState<EditorState>(generateEditorState(''))
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const epic5542bug6038 = useFlag(EPIC_5542_BUG_6038)

  const onContentStateChange = (state: Draft.DraftModel.Encoding.RawDraftContentState) => {
    try {
      if (currentBlocks !== 0 && currentBlocks < state?.blocks?.length && isBlockEnd) {
        const focusKey = editorState.getSelection().getFocusKey()
        if (focusKey) {
          const focusElement = document.querySelector(`div[data-offset-key="${focusKey}-0-0"]`) as HTMLElement
          const wrapperElement = document.querySelector('.rdw-editor-main') as HTMLElement
          const blockOffsetTop = focusElement?.offsetTop || 0
          const blockHeight = focusElement?.clientHeight || 0
          const scrollTop = wrapperElement?.scrollTop || 0
          const wrapperHeight = wrapperElement?.clientHeight || 0

          if (blockOffsetTop + blockHeight > scrollTop + wrapperHeight) {
            wrapperElement?.scroll({ behavior: 'smooth', top: blockOffsetTop + blockHeight - wrapperHeight })
          }
          const rect = focusElement.getBoundingClientRect()
          const wrapperRect = wrapperElement.getBoundingClientRect()
          if (
            rect.top + rect.height > window.innerHeight &&
            wrapperRect.top + wrapperRect.height > window.innerHeight
          ) {
            window.scrollTo(
              0,
              window.scrollY +
                Math.min(rect.top + rect.height, wrapperRect.top + wrapperRect.height) +
                24 -
                window.innerHeight,
            )
          } else {
            focusElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
          }
        }
      }
      setCurrentBlocks(state?.blocks?.length)
      setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    } catch {}
  }

  const onEditorStateChange = (e: EditorState) => {
    setIsEdited(true)
    if (epic5542bug6038) setEditorState(getCleanEditorState(editorState, e))
    else setEditorState(e)
    setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  useEffect(() => {
    // Prevent get initial value once touched
    if (!isEdited) setEditorState(generateEditorState(value || ''))
  }, [value])

  useEffect(() => {
    setEditorState(generateEditorState(value || ''))
  }, [isEditedByExternal])

  let background

  if (readOnly) {
    background = { background: MthColor.LIGHTGRAY }
  } else if (whiteBackground) {
    background = { background: MthColor.WHITE }
  }
  return (
    <Box
      data-testid={testId}
      // @ts-ignore
      sx={{
        ...(classStyle ? classStyle : null),
        ...bulletEditorClasses.editor,
        ...(error && bulletEditorClasses.editorInvalid),
        'div.rdw-editor-main': {
          minHeight: height ? height : '300px',
          maxHeight: maxHeight ? maxHeight : '350px',
          overflow: 'auto',
          wordBreak: wordBreak ? wordBreak : 'break-word',
          px: '10px',
          '.public-DraftStyleDefault-block': {
            margin: 0,
          },
          ...(!isNil(background) ? background : null),
          ...(readOnly ? { cursor: 'not-allowed' } : null),
        },
        marginY: 'auto',
      }}
    >
      <Editor
        readOnly={readOnly}
        onContentStateChange={onContentStateChange}
        placeholder={readOnly ? '' : '  Type here...'}
        editorState={editorState}
        handlePastedText={(_, html, editorState) => {
          return handlePastedText(html, setEditorState, editorState)
        }}
        onEditorStateChange={(e) => {
          onEditorStateChange(e)
        }}
        onBlur={() => isFunction(handleFocusLost) && handleFocusLost()}
        toolbar={{
          options: getOptions(ADMIN_OPTIONS, OPTIONS_TO_REMOVE, isOptionRemove),
          fontFamily: {
            options: Object.values(fontFamilyEnum),
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
        }}
        toolbarHidden={readOnly || hideToolbar}
      />
    </Box>
  )
}

export default MthBulletEditor
