import { useEffect } from 'react'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { CustomModal } from '@mth/components/CustomModal/CustomModals'
import { EMPTY_STRING } from '@mth/constants'
import { fontFamilyEnum, HubspotITRAnswers } from '@mth/enums'
import { HubspotModalType } from './hubspotModal.type'
import { Paragraph } from '../shared/Paragraph'

export const HubspotModal: React.FC<HubspotModalType> = ({
  onCancel,
  parentUniqueId,
  email,
  regionId,
  onSubmit = () => {},
  showFinalMessage,
  selectedItrOption,
  setSelectedItrOption,
  extraFieldContent,
  setExtraFieldContent,
  itrHubspotLoading,
  showErrorMessage,
  showSubmissionError,
}) => {
  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormError') {
        const iframe = document.querySelector<HTMLIFrameElement>('iframe.hs-form-iframe')
        if (iframe && iframe.contentDocument) {
          const formDoc = iframe.contentDocument

          const errorMessages = formDoc.querySelectorAll<HTMLUListElement>(
            'div.hs_error_rollup ul.no-list.hs-error-msgs.inputs-list',
          )
          if (errorMessages.length > 0) {
            errorMessages.forEach((errorMessage) => (errorMessage.style.display = 'none'))
          }
        }
      }
    }

    window.addEventListener('message', messageHandler)
    return () => window.removeEventListener('message', messageHandler)
  }, [])

  const selectExtraField = (selectedOption: string) => {
    if (selectedOption === HubspotITRAnswers.YES_SOME) {
      return (
        <Box sx={{ marginTop: '20px', marginLeft: '2%', width: '90%' }}>
          <Typography variant='body2' sx={{ fontSize: 12 }}>
            Can you please list your student(s) that won&apos;t be joining us next year?
            <Typography component='span' sx={{ color: 'red' }}>
              {' '}
              *
            </Typography>
          </Typography>
          <TextField
            fullWidth
            size='small'
            variant='outlined'
            placeholder='Please separate by commas'
            value={extraFieldContent}
            onChange={(e) => setExtraFieldContent(e.target.value)}
            sx={{ marginTop: '5px', marginBottom: '20px' }}
            error={showErrorMessage}
            helperText={showErrorMessage ? 'This is a required field' : ''}
          />
        </Box>
      )
    } else if (selectedOption === HubspotITRAnswers.YES_NEW) {
      return (
        <Box sx={{ marginTop: '20px', marginLeft: '2%', width: '90%' }}>
          <Typography variant='body2' sx={{ fontSize: 12 }}>
            Can you please list out the student(s) you want to add?
            <Typography component='span' sx={{ color: 'red' }}>
              {' '}
              *
            </Typography>
          </Typography>
          <TextField
            fullWidth
            size='small'
            variant='outlined'
            placeholder='Please separate by commas'
            value={extraFieldContent}
            onChange={(e) => setExtraFieldContent(e.target.value)}
            sx={{ marginTop: '5px', marginBottom: '20px' }}
            error={showErrorMessage}
            helperText={showErrorMessage ? 'This is a required field' : ''}
          />
        </Box>
      )
    } else if (selectedOption === HubspotITRAnswers.NO) {
      return (
        <Box sx={{ marginTop: '20px', marginLeft: '2%', width: '90%' }}>
          <Typography variant='body2' sx={{ fontSize: 12 }}>
            We&apos;re sad to see you go! Can you share your reason for the change?
          </Typography>
          <TextField
            fullWidth
            size='small'
            variant='outlined'
            placeholder={EMPTY_STRING}
            value={extraFieldContent}
            onChange={(e) => setExtraFieldContent(e.target.value)}
            sx={{ marginTop: '5px', marginBottom: '20px' }}
            multiline
            rows={4}
          />
        </Box>
      )
    }
    return <></>
  }

  const displaySwitch = () => {
    if (!showFinalMessage && !showSubmissionError) {
      return (
        <Box>
          <Typography
            sx={{ marginBottom: '15px', marginTop: '10px', fontSize: 14, fontFamily: fontFamilyEnum.HeavyFont }}
          >
            Please fill out the form below to submit your Intent to Re-enroll for the 2025-26 school year. If you have
            any questions, please reach out to <a href='help@opened.co'>help@opened.co.</a>
          </Typography>
          <Box
            sx={{
              paddingLeft: '5px',
            }}
          >
            <Box sx={{ marginTop: '15px', marginLeft: '10px', overflow: 'auto', width: '100%', display: 'block' }}>
              <Typography
                sx={{
                  marginBottom: '10px',
                  marginTop: '10px',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                Are you planning to enroll in OpenEd for the 2025-2026 school year?
              </Typography>
              <FormControl component='fieldset'>
                <RadioGroup
                  aria-labelledby='itr-options-group'
                  name='itr-options'
                  value={selectedItrOption}
                  onChange={(e) => setSelectedItrOption(e.target.value)}
                >
                  {[
                    { value: HubspotITRAnswers.YES, label: HubspotITRAnswers.YES },
                    { value: HubspotITRAnswers.YES_SOME, label: HubspotITRAnswers.YES_SOME },
                    { value: HubspotITRAnswers.YES_NEW, label: HubspotITRAnswers.YES_NEW },
                    { value: HubspotITRAnswers.NO, label: HubspotITRAnswers.NO },
                  ].map(({ value, label }) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio sx={{ marginRight: '10px' }} />}
                      label={
                        <Paragraph size='large' fontWeight='600'>
                          {label}
                        </Paragraph>
                      }
                      sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {/* Render the text field at the bottom if YES_SOME or YES_NEW is selected */}
              {selectExtraField(selectedItrOption)}
            </Box>
          </Box>
        </Box>
      )
    } else if (showSubmissionError) {
      return (
        <Box>
          <Typography sx={{ marginBottom: '15px', fontSize: 14, fontFamily: fontFamilyEnum.HeavyFont }}>
            There was an error with your ITR form submission. Please log in to infocenter to try again.
          </Typography>
        </Box>
      )
    }
    return (
      <Box>
        <Typography sx={{ marginBottom: '15px', fontSize: 14, fontFamily: fontFamilyEnum.HeavyFont }}>
          Congrats! Your selection for the 2025-26 school year has been saved. We will let you know when it&apos;s time
          to complete the next steps.
        </Typography>
      </Box>
    )
  }

  return (
    <CustomModal
      title='Intent to Re-Enroll'
      description={!showFinalMessage ? `It's time to secure your spot for next school year!` : EMPTY_STRING}
      descriptionSx={{ fontSize: 17, fontFamily: fontFamilyEnum.HeavyFont, fontWeight: 'bold' }}
      subDescription={displaySwitch()}
      confirmStr={!showFinalMessage ? 'Confirm' : 'Close'}
      cancelStr='Cancel'
      onClose={onCancel}
      showCancel={!showFinalMessage}
      onConfirm={
        !showFinalMessage
          ? () => onSubmit(true, email, parentUniqueId, regionId, selectedItrOption, extraFieldContent)
          : onCancel
      }
      confirmDisabled={itrHubspotLoading}
    />
  )
}
