import React, { useEffect } from 'react'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { OPERATIONAL_SEND_SMS } from '@mth/constants'
import { Paragraph } from '@mth/core/components/shared/Paragraph'
import {
  MthColor,
  MthTitle,
  NotificationSettings,
  ParticipationNotificationLabel,
  ParticipationNotificationSettings,
  TEXT_SIZE,
} from '@mth/enums'
import { NotificationsSettingType } from '@mth/types'

type NotificationOptionsSetting = {
  notificationSettings: NotificationsSettingType | null
  selectedSetting: number | null
  setSelectedSetting: React.Dispatch<React.SetStateAction<NotificationSettings | null>>
  phoneAvailability: boolean
  isDisabled?: boolean
  setIsChanged?: React.Dispatch<React.SetStateAction<boolean>>
}

export const NotificationOptionsSetting: React.FC<NotificationOptionsSetting> = ({
  notificationSettings,
  selectedSetting,
  setSelectedSetting,
  phoneAvailability,
  isDisabled,
  setIsChanged,
}) => {
  const operationalSendSms = useFlag(OPERATIONAL_SEND_SMS)

  useEffect(() => {
    let initialSetting = selectedSetting ?? ParticipationNotificationSettings.EMAIL

    if (!operationalSendSms) {
      initialSetting = ParticipationNotificationSettings.EMAIL
    }

    setSelectedSetting(initialSetting)
  }, [notificationSettings, phoneAvailability])
  const handleOptionSelected = (option: number) => {
    setSelectedSetting(option as NotificationSettings)
    if (setIsChanged) setIsChanged(true)
  }

  return (
    <Box sx={{ paddingLeft: '10px', marginTop: '10px', overflow: 'auto', textAlign: 'start' }}>
      <FormControl>
        <RadioGroup
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={selectedSetting ?? NotificationSettings.EMAIL}
          onChange={(e) => handleOptionSelected(Number(e.target.value))}
        >
          <Tooltip title={operationalSendSms ? '' : MthTitle.SMS_NOT_AVAILABLE}>
            <FormControlLabel
              value={NotificationSettings.ALL}
              control={<Radio sx={{ marginRight: '5px' }} />}
              label={
                <Paragraph
                  size={TEXT_SIZE.LARGE}
                  fontWeight='600'
                  color={
                    operationalSendSms && !isDisabled && phoneAvailability ? `${MthColor.BLACK}` : `${MthColor.GRAY}`
                  }
                >
                  {ParticipationNotificationLabel.ALL}
                </Paragraph>
              }
              disabled={!operationalSendSms || isDisabled || !phoneAvailability}
            />
          </Tooltip>
          <FormControlLabel
            value={NotificationSettings.EMAIL}
            control={<Radio sx={{ marginRight: '5px' }} />}
            label={
              <Paragraph
                size={TEXT_SIZE.LARGE}
                fontWeight='600'
                color={!isDisabled ? `${MthColor.BLACK}` : `${MthColor.GRAY}`}
              >
                {ParticipationNotificationLabel.EMAIL}
              </Paragraph>
            }
            disabled={isDisabled}
          />
          <Tooltip title={operationalSendSms || !isDisabled ? '' : MthTitle.SMS_NOT_AVAILABLE}>
            <FormControlLabel
              value={NotificationSettings.SMS}
              control={<Radio sx={{ marginRight: '5px' }} />}
              label={
                <Paragraph
                  size={TEXT_SIZE.LARGE}
                  fontWeight='600'
                  color={
                    operationalSendSms && !isDisabled && phoneAvailability ? `${MthColor.BLACK}` : `${MthColor.GRAY}`
                  }
                >
                  {ParticipationNotificationLabel.SMS}
                </Paragraph>
              }
              disabled={!operationalSendSms || isDisabled || !phoneAvailability}
            />
          </Tooltip>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
