import { isNil } from 'lodash'

export const InterventionNone = 0 // this help to set the default value of the person intervention

export enum PersonInterventionLabels {
  NONE = 'None',
  MISSING_LAST_LOG = 'Missing Last Log',
  MISSING_LAST_TWO_LOGS = 'Missing Last Two Logs',
  AT_RISK = 'At-Risk',
  WORKING_WITH_PARENT_SUPPORT = 'Working with Parent Support',
  PLANS_TO_WITHDRAW = 'Plans to Withdraw',
  WATCH_LIST = 'Watch List',
  WORKING_WITH_DIPLOMA_SEEKING_SPECIALISTS = 'Working with Diploma Seeking Specialists',
  ASSESSMENT_INTERVENTIONS = 'Assessment Interventions',
  SCHEDULE_REQUIRED = 'Schedule Required',
}

export enum INTERVENTION_STATUS {
  MISSING_LAST_LOG = 1,
  MISSING_LAST_TWO_LOGS,
  AT_RISK,
  WORKING_WITH_PARENT_SUPPORT,
  PLANS_TO_WITHDRAW,
  WATCH_LIST,
  WORKING_WITH_DIPLOMA_SEEKING_SPECIALISTS,
  ASSESSMENT_INTERVENTIONS,
  SCHEDULE_REQUIRED,
}

const interventionStatusMap: Record<number, string> = {
  [InterventionNone]: PersonInterventionLabels.NONE,
  [INTERVENTION_STATUS.MISSING_LAST_LOG]: PersonInterventionLabels.MISSING_LAST_LOG,
  [INTERVENTION_STATUS.MISSING_LAST_TWO_LOGS]: PersonInterventionLabels.MISSING_LAST_TWO_LOGS,
  [INTERVENTION_STATUS.AT_RISK]: PersonInterventionLabels.AT_RISK,
  [INTERVENTION_STATUS.WORKING_WITH_PARENT_SUPPORT]: PersonInterventionLabels.WORKING_WITH_PARENT_SUPPORT,
  [INTERVENTION_STATUS.PLANS_TO_WITHDRAW]: PersonInterventionLabels.PLANS_TO_WITHDRAW,
  [INTERVENTION_STATUS.WATCH_LIST]: PersonInterventionLabels.WATCH_LIST,
  [INTERVENTION_STATUS.WORKING_WITH_DIPLOMA_SEEKING_SPECIALISTS]:
    PersonInterventionLabels.WORKING_WITH_DIPLOMA_SEEKING_SPECIALISTS,
  [INTERVENTION_STATUS.ASSESSMENT_INTERVENTIONS]: PersonInterventionLabels.ASSESSMENT_INTERVENTIONS,
  [INTERVENTION_STATUS.SCHEDULE_REQUIRED]: PersonInterventionLabels.SCHEDULE_REQUIRED,
}

export function translateInterventionStatus(status: number | undefined): PersonInterventionLabels {
  if (isNil(status)) return PersonInterventionLabels.NONE
  if (!Object.hasOwn(interventionStatusMap, status)) {
    return PersonInterventionLabels.NONE
  }
  return interventionStatusMap[status as keyof typeof interventionStatusMap] as PersonInterventionLabels
}

export function translateInterventionStatusLabel(
  label: PersonInterventionLabels,
): INTERVENTION_STATUS | typeof InterventionNone {
  const validLabels = Object.values(PersonInterventionLabels)
  if (!validLabels.includes(label)) {
    throw 'Not valid intervention status'
  }

  for (const [key, value] of Object.entries(interventionStatusMap)) {
    if (value === label) {
      return Number(key) as INTERVENTION_STATUS
    }
  }

  return InterventionNone
}
