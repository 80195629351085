import React, { CSSProperties, ReactNode } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import { customModalClasses } from '@mth/components/CustomModal/styles'
import { MthColor, fontFamilyEnum } from '@mth/enums'
import { commonClasses } from '@mth/styles/common.style'

export type CustomModalType = {
  testId?: string
  title: string
  description: string
  subDescription?: string | ReactNode
  onClose: () => void
  onConfirm: () => void
  confirmStr?: string
  cancelStr?: string
  backgroundColor?: string
  showIcon?: boolean
  showCancel?: boolean
  isResourceModal?: boolean
  topRightCancel?: boolean
  descriptionSx?: CSSProperties
  onCancelIcon?: () => void
  confirmDisabled?: boolean
  showButtonsVertical?: boolean
  modalSx?: CSSProperties
}

export const CustomModal: React.FC<CustomModalType> = ({
  testId,
  title,
  description,
  subDescription,
  onClose,
  onConfirm,
  confirmStr = 'Confirm',
  cancelStr = 'Cancel',
  backgroundColor = MthColor.WHITE,
  showIcon = true,
  showCancel = true,
  isResourceModal = true,
  topRightCancel = false,
  descriptionSx,
  onCancelIcon,
  confirmDisabled = false,
  showButtonsVertical = false,
  modalSx = {},
}) => {
  const buttonsInVertical = showButtonsVertical && showCancel
  return (
    <Modal
      data-testid={testId}
      open={true}
      aria-labelledby='child-modal-title'
      disableAutoFocus={true}
      aria-describedby='child-modal-description'
    >
      <Box
        sx={{
          ...commonClasses.modalWrap,
          maxWidth: subDescription ? '550px' : '450px',
          backgroundColor: backgroundColor,
          borderRadius: 2,
          ...(!topRightCancel ? { p: 4 } : { paddingTop: 2, paddingRight: 4, paddingBottom: 4, paddingLeft: 4 }),
          ...modalSx,
        }}
      >
        {topRightCancel && (
          <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
            <Button sx={customModalClasses.cancelIcon} onClick={onCancelIcon}>
              <CloseIcon fontSize='small' />
            </Button>
          </Grid>
        )}

        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant='h5'
            fontWeight={'bold'}
            fontFamily={isResourceModal ? fontFamilyEnum.HeavyFont : fontFamilyEnum.VisbyFont}
          >
            {title}
          </Typography>
          {showIcon && <ErrorOutlineIcon sx={{ fontSize: 50, margin: '20px 0px' }} />}
          <Typography
            sx={{
              fontSize: isResourceModal ? 14 : '1rem',
              fontFamily: isResourceModal ? fontFamilyEnum.HeavyFont : fontFamilyEnum.VisbyFont,
              ...(descriptionSx ? descriptionSx : {}),
            }}
            marginTop={showIcon ? 0 : 4}
            component={'p'}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) || '' }}
          />
          {subDescription && <Typography>{subDescription}</Typography>}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '30px',
              gap: '40px',
            }}
          >
            {showCancel && (
              <Button sx={customModalClasses.cancelBtn} onClick={onClose}>
                {cancelStr}
              </Button>
            )}
            {!buttonsInVertical && (
              <Button sx={customModalClasses.confirmBtn} onClick={onConfirm} disabled={confirmDisabled}>
                {confirmStr}
              </Button>
            )}
          </Box>
          {buttonsInVertical && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '30px',
                gap: '40px',
              }}
            >
              <Button sx={customModalClasses.confirmBtn} onClick={onConfirm} disabled={confirmDisabled}>
                {confirmStr}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
