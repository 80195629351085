import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Button, Modal, Typography, Box, CircularProgress } from '@mui/material'
import { MthColor } from '@mth/enums'
import { testIDs } from './__tests__/Warning.testIds'
import { useStyles } from './styles'
import { WarningModalProps } from './types'
import { Paragraph } from '../../core/components/shared/Paragraph/Paragraph'

export const WarningModal: React.FC<WarningModalProps> = ({
  testId,
  handleModem,
  title,
  subtitle,
  secondSubtitle,
  btntitle = 'Submit',
  canceltitle = '',
  handleSubmit,
  showIcon = true,
  children,
  textCenter,
  modalWidth,
  error = false,
  upperCase = true,
  disableButton = false,
  progress = false,
  sx = {},
  loadingButton = false,
}) => {
  const classes = useStyles()
  return (
    <Modal
      data-testid={testId}
      open={true}
      onClose={handleModem}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box className={classes.modalCard} sx={{ width: modalWidth ? modalWidth : '470px', ...sx }}>
        <Box className={classes.header} sx={{ textTransform: upperCase ? 'capitalize' : 'inherit' }}>
          <Typography variant='h5' fontSize={'20px'} fontWeight={'bold'}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.content}>
          {showIcon && <ErrorOutlineIcon className={classes.errorOutline} />}
          <Paragraph
            size='large'
            fontWeight='600'
            // @ts-ignore
            color={!error ? MthColor.SYSTEM_01 : MthColor.RED}
            sx={{ textAlign: textCenter ? 'center' : 'left', mt: showIcon ? '0' : '56px' }}
          >
            {subtitle}
          </Paragraph>
          {secondSubtitle && (
            <Paragraph
              size='large'
              fontWeight='600'
              // @ts-ignore
              color={!error ? MthColor.SYSTEM_01 : MthColor.RED}
              sx={{ textAlign: textCenter ? 'center' : 'left', mt: showIcon ? '0' : '56px' }}
            >
              {secondSubtitle}
            </Paragraph>
          )}
          {children}
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
            {canceltitle != '' && (
              <Button
                data-testid={testIDs.cancelButton}
                variant='contained'
                disableElevation
                className={classes.button}
                onClick={handleModem}
                disabled={loadingButton}
              >
                {canceltitle}
              </Button>
            )}
            {!disableButton && (
              <Button
                data-testid={testIDs.positiveCTAButton(btntitle)}
                variant='contained'
                disableElevation
                className={classes.submit}
                onClick={handleSubmit}
                disabled={loadingButton}
              >
                {btntitle}
              </Button>
            )}
          </Box>
          {progress && <CircularProgress size={20} sx={{ marginTop: 3 }} />}
        </Box>
      </Box>
    </Modal>
  )
}
