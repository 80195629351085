import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { ReimbursementRequestStatus } from '@mth/enums'

export const REIMBURSEMENT_REQUEST_STATUS_ITEMS: DropDownItem[] = [
  { label: 'Not Submitted', value: ReimbursementRequestStatus.DRAFT.toString() },
  { label: 'Submitted', value: ReimbursementRequestStatus.SUBMITTED.toString() },
  { label: 'Resubmitted', value: ReimbursementRequestStatus.RESUBMITTED.toString() },
  { label: 'Updates Required', value: ReimbursementRequestStatus.UPDATES_REQUIRED.toString() },
  { label: 'Approved', value: ReimbursementRequestStatus.APPROVED.toString() },
  { label: 'Paid', value: ReimbursementRequestStatus.PAID.toString() },
  { label: 'Ordered', value: ReimbursementRequestStatus.ORDERED.toString() },
  { label: 'Returned', value: ReimbursementRequestStatus.RETURNED.toString() },
]

// remove this with the FF epic4861Story5880
export const REIMBURSEMENT_REQUEST_STATUS_ITEM_WITH_SENT_TO_HYPERWALLET: DropDownItem[] = [
  { label: 'Not Submitted', value: ReimbursementRequestStatus.DRAFT.toString() },
  { label: 'Submitted', value: ReimbursementRequestStatus.SUBMITTED.toString() },
  { label: 'Resubmitted', value: ReimbursementRequestStatus.RESUBMITTED.toString() },
  { label: 'Updates Required', value: ReimbursementRequestStatus.UPDATES_REQUIRED.toString() },
  { label: 'Approved', value: ReimbursementRequestStatus.APPROVED.toString() },
  { label: 'Paid', value: ReimbursementRequestStatus.PAID.toString() },
  { label: 'Ordered', value: ReimbursementRequestStatus.ORDERED.toString() },
  { label: 'Returned', value: ReimbursementRequestStatus.RETURNED.toString() },
  { label: 'Sent to HyperWallet', value: ReimbursementRequestStatus.SENT_TO_HYPERWALLET.toString() },
]
