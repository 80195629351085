import { ReimbursementRequestStatus } from '@mth/enums'

// THIS SHOULD BE REMOVED WHEN FF
export const ACTIVE_REIMBURSEMENT_REQUEST_STATUSES: ReimbursementRequestStatus[] = [
  ReimbursementRequestStatus.APPROVED,
  ReimbursementRequestStatus.PAID,
  ReimbursementRequestStatus.ORDERED,
  ReimbursementRequestStatus.PROCESSED,
]
export const ACTIVE_REIMBURSEMENT_REQUEST_STATUSES_UPDATED: ReimbursementRequestStatus[] = [
  ReimbursementRequestStatus.APPROVED,
  ReimbursementRequestStatus.PAID,
  ReimbursementRequestStatus.ORDERED,
  ReimbursementRequestStatus.PROCESSED,
  ReimbursementRequestStatus.SENT_TO_HYPERWALLET,
]
